import React, { useState, useEffect, useContext } from 'react'
// import { faunaQueries } from '../fauna/query-manager'
// import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import LightGallery from 'lightgallery/react';
// // import styles
// // import 'lightgallery/css/lightgallery.css';
// // import 'lightgallery/css/lg-zoom.css';
// // import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// // import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';


import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import planning from './../images/fulls/gs8-planning.png';
import gs1 from './../images/fulls/gs8-sme.jpg';
import gs2 from './../images/fulls/gs8-screens.jpg';
import gs3 from './../images/fulls/gs8-applicant-user-flow.jpg';
import gs4 from './../images/fulls/gs8-waitlist-user-flow.jpg';
import gs5 from './../images/fulls/gs8-HA-facing-user-flow.jpg';
import gs8app from './../images/fulls/gs8-app.png';
import haDashboard from './../images/fulls/gs8-ha-waitlist-dashboard.png';
import waitlistPrefs from './../images/fulls/gs8-ha-waitlist-prefs.png';
import crm from './../images/fulls/gs8-ha-crm.png';
import upgradeModal from './../images/fulls/gs8-ha-upgrade-modal.png';
import applicantDetails from './../images/fulls/gs8-contact-health.png';

const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};
const Gs8hawl = props => {

  const [state, setState] = useState({
    fweets: [],
    loaded: false,
    error: false
  })
  const history = useHistory();

  // Fetch the fweets on first load.
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    }
  }, [user])

  
return (
  <>
  <header className="hero">
  <h2>Centralized Section 8 Waitlist System</h2>
    <p>GoSection8 already had a monopoly over the Section8 rental market through their strong relationships with housing authorities all over the country. While some revenue came from a subscription-based service for property owners, most of their revenue came from ad impressions.
</p><p>
When the call center told the CEO that the #1 question they got was “How do I apply for Section 8?” He realized the amount of revenue they could generate from creating a centralized platform for low-income families from all over the country to apply for Section8 housing. 
</p>
    <ul className="information">
        {/* <li><span>Client:</span> US Housing Authorities, GoSection8</li> */}
        <li><span>Technologies: </span>Axure, KnockoutJS/CSS/HTML, .NET</li>
    </ul>
</header>
<main className="main-content project-wrapper">
<SRLWrapper options={options}>

{/* <section style={{flexDirection:'column', padding:'2rem'}}>
</section>
<section>
<img src={planning} />
</section> */}

<section>
  <div className="text-wrap">
    <h3>Identifying an unmet social need</h3>  
    <p>Unfortunately, every housing authority has a slightly different application form with different questions for both Section 8 and public housing.</p>
    <p>I analyzed multiple Section 8 and public housing “pre applications” and “full applications”, then consolidated all of the questions into a 
      spreadsheet with copious annotations for consultation with SMEs and the rest of our team to determine the best organizational structure 
      for this complex dynamically generated form. The backend team then used this spreadsheet and my prototype to educate their data model.</p>
  </div>
  <div className="image-wrap">
    <a href={planning}>
        <img src={planning} alt="Designing the centralized application's content and information architecture" />
    </a>
  </div>
</section>

{/* <section>
  <div className="text-wrap">
    <h3><strong>Step 1 </strong> | Triage</h3>  
    <h2>GoSection8 (1/6) Waitlist Project</h2>
							<p>I was involved in this project from beginning to end, writing out requirements, mocking up designs, building out the frontend, and partnering with backend engineers to design the data model. 2016-2017.</p>
				
  </div>
  <div className="image-wrap">
    <a href={gs1}>
        <img src={gs1} alt="" />
    </a>
  </div>
</section> */}

<section>
  <div className="text-wrap">
    <h3>Defining project scope and IA</h3>  
							<p>The final project architecture consisted of:
              </p>
                <ol>
                  {/* <li>A distinct authentication process for new waitlist applicants (vs existing site members)</li> */}
                  <li>A dashboard for housing authorities to manage applications</li>
                  <li>A dynamically-generated waitlist application form which displays content based on the user (pre applicant, 
                    full applicant, HA user) and housing authority (since application questions differ by jurisdiction).</li>
                  <li>A series of search engine optimized landing pages designed to generate ad revenue</li>
                </ol>
  </div>
  <div className="image-wrap">
    <a href={gs3}>
        <img src={gs3} alt="Project scope and IA" />
    </a>
  </div>
</section>

{/* <section>
  <div className="text-wrap">
    <h3><strong>Step 1 </strong> | Triage</h3>  
    <h2>GoSection8 (3/6) Waitlist Application Task Flow</h2>
							<p>Screenshot of the Axure prototype of the applicant-facing userflow I designed and coded. 2016-2017.</p>
              <p>
   This might be a little buggy because it was created on a much older version of Axure.</p>
  <p><a href="https://97c0j2.axshare.com/prototype/login/97c0j2">Axure prototype</a> (same password as for this site)</p>

  </div>
  <div className="image-wrap">
    <a href={gs4}>
        <img src={gs4} alt="" />
    </a>
  </div>
</section> */}

<section>
  <div className="text-wrap">
    <h3>1. Dashboard for housing authorities</h3> 
							<p>Working with SMEs to iron out the project requirements, I designed and coded an applicant management system for 
                housing authorities to monitor waitlist health, outreach to various demographics, etc.</p>
  </div>
  {/* <div className="image-wrap">
    <a href={haDashboard}>
        <img src={haDashboard} alt="Housing authority dashboard" />
    </a>
  </div> */}
  <div className="image-wrap thumbnails">
          <div className="lg-react-element">
          <a href={haDashboard}>
                    <img src={haDashboard} alt="Housing authority dashboard" />
                </a>
                <a href={crm}>
                    <img src={crm}  alt="Waitlist Health Management System" />
                </a>
                <a href={applicantDetails}>
                    <img src={applicantDetails}  alt="Waitlist Applicant Details" />
                </a>
                <a href={waitlistPrefs}>
                    <img src={waitlistPrefs}  alt="Tools to monitor outreach success" />
                </a>
                <a href={upgradeModal}>
                    <img src={upgradeModal}  alt="Incorporating a product-led growth model" />
                </a>
          </div>

        {/* </SRLWrapper> */}

  </div>
</section>

<section>
  <div className="text-wrap">
    <h3>2. Dynamic waitlist application form</h3> 
							<p>The application needed to be ADA compliant, accessible by both the applicant and the housing authorities (for cases where the housing authority helped the applicant fill it out), and in some cases, dynamically display different form fields based on the applicant's location.</p>
  </div>
  <div className="image-wrap">
    <a href={gs8app}>
        <img className="border" src={gs8app} alt="Housing authority-accessible application" />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3>3. SEO-optimized landing pages</h3>  
							<p>Being a business, GoSection8 wanted to make money out of this venture, so I strategized, designed, and coded a system of 
								dynamically-generated landing pages to take advantage of the high SEO ranking they got from organic traffic from their housing authority referrals.</p>
  </div>
  <div className="image-wrap">
    <a href={gs5}>
        <img src={gs5} alt="Waitlist Project Landing Pages" />
    </a>
  </div>
</section>

</SRLWrapper>

<section className="center prev-next">
  <h3>
    <Link to="/fauna-docs">
      <FontAwesomeIcon icon={faArrowLeft} />Database Docs
    </Link>
    <span>|</span>
    <Link to="/commerce-signals">
     Ad Campaign Dashboard<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>

</main>
</>

  )
}
Gs8hawl.propTypes = {}

export default Gs8hawl