import React, { useState, useEffect, useContext } from 'react'
// import { faunaQueries } from '../fauna/query-manager'
// import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import LightGallery from 'lightgallery/react';
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';
// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';
// import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


import { SRLWrapper } from "simple-react-lightbox";



import Th1 from './../images/fulls/fauna-dashboard-framed.png';
import oldDb from './../images/fulls/fauna-old-dashboard.png';
import mobileWires from './../images/fulls/fauna-mobile-wireframes.png';
import mobileIa from './../images/fulls/fauna-mobile-ia.png';
import challenges from './../images/fulls/fauna-challenges.png';
import desktopWire from './../images/fulls/fauna-desktop-wireframe.png';
import Th3 from './../images/fulls/fauna-funnel.png';
import collections from './../images/fulls/fauna-db-collections.png';
import Th5 from './../images/fulls/fauna-mobile.png';
// import rg from './../images/fulls/fauna-rg-framed.png';
import userResearch from './../images/fulls/fauna-user-research.png';
import dbExplorer from './../images/fulls/fauna-db-explorer.png';
import faunaChart from './../images/fulls/fauna-mobile-chart.png';
import indexQuote from './../images/fulls/fauna-index-quote.png';
import indexBuilding from './../images/fulls/fauna-index-building.png';
import indexBuildingWoQuote from './../images/fulls/fauna-index-building-wo-quote.png';
import indexCheckbox from './../images/fulls/fauna-index-checkbox.png';
import indexTip from './../images/fulls/fauna-indexes-access-later-tip.png';
import indexTipDraft from './../images/fulls/fauna-indexes-access-later-draft.png';
import indexIncomplete from './../images/fulls/fauna-indexes-instances-missing.png';
import login from './../images/fulls/fauna-login.png';
import docsFx from './../images/fulls/fauna-documents-function.png';




const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};
const callbacks = {
  onSlideChange: object => console.log("onSlideChange ", object),
  onLightboxOpened: object => console.log("onLightboxOpened ", object),
  onLightboxClosed: object => console.log("onLightboxClosed ", object),
  onCountSlides: object => console.log("onCountSlides ", object)
};

const Fauna = ({ match, location, props }) => {
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const history = useHistory();
  const [profileData, setProfileData] = useState(undefined)
  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    } else{
    }
  }, [])

  const onInit = () => {
    console.log('lightGallery has been initialized');
};

  
  return (
    <>

    <header className="hero">
    <h1>FaunaDB Dashboard</h1>
    {/* <h5>2018-2021</h5> */}
    <p>April 2018 - 2021</p>
    <h3>The cloud console UI was really stunning. 
      {/* It works very well. It's well laid out. 
    Whoever designed it did a good job. Unfortunately, I don't have any criticisms of it 
    because it's better than anything I've experienced so far in terms of the UI */}
    ... Once I got 
    into the cloud console, it was the magic moment. I was like, 'Oh, okay. Yeah, this is good.'
</h3>
<div style={{textAlign:"right"}}>- user quote from general Fauna feedback session</div>

  </header>
    <main className="main-content project-wrapper">

    {/* 

    document.getElementById('lg-next-1').addEventListener('click', function () {console.log('thanks for clicking me!')}) 
    
    */}
    
    {/* <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href={Th1}>
                    <img alt="img1" src={Th1} />
                </a>
                <a href={oldDb}>
                    <img alt="img2" src={oldDb} />
                </a>
            </LightGallery> */}


    {/* <Gallery>
    <Item
      original="https://placekitten.com/1024/768?image=1"
      thumbnail="https://placekitten.com/80/60?image=1"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src="https://placekitten.com/80/60?image=1" />
      )}
    </Item>
    <Item
      original="https://placekitten.com/1024/768?image=2"
      thumbnail="https://placekitten.com/80/60?image=2"
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src="https://placekitten.com/80/60?image=2" />
      )}
    </Item>
  </Gallery> */}










    <SRLWrapper options={options} callbacks={callbacks}>
    {/* <a href={oldDb}>
                    <img  className="border" src={oldDb} alt="When I started in April 2018, the dashboard had a wall of text as its welcome screen." />
                </a>
                <a href={login}>
                    <img src={login} alt="As the only 100% ACID NoSQL database, Fauna was able to reduce operational overhead, and aspired to make databases easy for even frontend developers with no database experience." />
                </a>
                <a href={mobileWires}>
              <img src={mobileWires} alt="Wireframing the IA mobile-first" />
            </a>

            </SRLWrapper> */}

<section>
  <div className="text-wrap">
<h3>Project summary</h3>
{/* <h3>{thisProject.sections[1].title}</h3>    */}
<p>As the only 100% ACID NoSQL database, Fauna was able to reduce operational overhead, and aspired to make databases easy for even frontend developers with no database experience. <Link to="https://css-tricks.com/consistent-backends-and-ux:-why-should-you-care/" target="_blank">(Learn more in this
           series of CSS Tricks articles I edited.)</Link></p><p> Unfortunately, the data wasn't living the theory, retention rates were crisis-level low, and developers consistently lamented that they could not use FaunaDB because it was too diffficult to learn.</p>   
      <p>FaunaDB needed a better UI for learning, debugging, and monitoring.</p>

  </div>
  <div className="image-wrap">
    {/* https://www.lightgalleryjs.com/demos/captions/ */}
    <a href={login}>
                    <img src={login} alt="As the only 100% ACID NoSQL database, Fauna was able to reduce operational overhead, and aspired to make databases easy for even frontend developers with no database experience. Unfortunately, retention was low; users complained that Fauna was too hard to learn. Among other things, we needed a better UI for learning, debugging, and monitoring. Over the next 3 years working on this UI, I was the Lead Designer, usually the Product Manager, often the Frontend Engineering Manager, and occasionally a Frontend Dev and Tech Writer." />
                </a>
  </div>
</section>
<section>
        <div className="text-wrap">
  <h3><strong>Step 1 </strong> | Triage</h3>  
<p>When I started in 2018, the user-facing dashboard was an open-source proof of concept (see image) with enough basic functionality to be useful, 
  but insufficient for most learning, debugging, and monitoring use cases.</p>
  <p>I was immediately given an off-shore team of 4 frontend developers to build something new based on my forthcoming requirements and mocks. Since those 
     designs would not be ready for a few weeks, we also started working on "low-hanging fruit" in the existing product (and <Link to="/fauna-docs">documentation</Link>) as a stopgap.</p>
        </div>
        <div className="image-wrap">
        <a href={oldDb}>
                    <img  className="border" src={oldDb} alt="When I started in April 2018, the dashboard was just a proof-of-concept someone had developed over a weekend. We decided to build something new from scratch, but make small improvements to the old dashboard in the interim." />
                </a>
        </div>
      </section>

    <section>
      <div className="text-wrap">
      <h3><strong>Step 2 </strong>| Define the product requirements</h3>                
      <p>At the time, Fauna had two products (cloud and on-prem), serving two different personas, and the new dashboard needed to accommodate both:</p>
      <ol>
      <li>The Cloud/Dev persona would need the “data explorer” dashboard you see in production today.
      </li>
      <li>The On-prem/Operator persona (Sys Admin, DBA, etc.) would also need a “cluster health management” console.</li>
      </ol>
      <p>I interviewed developers who had never used Fauna, current users, ex-users, and internal SMEs to help me write the user personas, their jobs to be done (JTBD), and the PRD.</p>

      </div>        <div className="image-wrap">
      <a target="_blank" href={Th3}>
                    <img src={Th3} alt="Mapping out the getting started funnels, and how we would track/measure user onboarding. Some users might only visit the UI to create a key, and otherwise interact exclusively via the drivers." />
                </a>
      </div>
    </section>

    <section>
          <div className="text-wrap">
          <h3><strong>Step 3 </strong>| Brainstorm mobile-first</h3>  
          <p><a target="_blank" href="https://www.figma.com/proto/gpEr3BwmVaTh4uixnjUepa/portfolio-prototypes?node-id=24%3A942&scaling=min-zoom&page-id=24%3A542&starting-point-node-id=24%3A942&show-proto-sidebar=1">mobile nav wireframe prototypes</a></p>  
   <p>Designing mobile-first was initially a tough sell, since database management is presumably something one would do at work on desktop, but:</p>
    <ul>
      <li>Being a startup, most of our target users were early adopters and hobbyists who regularly explored new technology on their phones outside of work.</li>
     <li>I've always designed mobile-first because mobile designs are smaller, and thus faster for initial wireframing and ideation.</li>
     <li>It’s easier to add white space to desktop views than to squeeze elements into mobile views, and it’s usually poor UX to remove them and lose parity between device types.</li>
</ul>  
<p>Once the product was released, qualitative and quantitative user data helped maintain the value of a mobile-friendly dashboard.</p>
    </div>

          <div className="image-wrap thumbnails col-of-2">
          <div className="lg-react-element">
            <a href={mobileWires}>
              <img src={mobileWires} alt="Wireframing the IA mobile-first (Close this gallery for link to wireframes)." />
            </a>
            <a style={{maxWidth:'300px',margin:'0 auto'}} className="border" href={faunaChart}>
              <img src={faunaChart} alt="Experimenting with color palettes, drawing from a green provided by the marketing/branding team." />
            </a>
            </div>
          </div>
    </section>

<section>
        <div className="text-wrap">
          <h3>
          <strong>Step 4 </strong>| Determine LOEs, Identify technical challenges
          </h3>
    <p>Despite a solid PRD, it’s often not until you have wireframes that you really "see" how much work is ahead. Challenges included:</p>
  <ol>
    <li>While the developer persona should not be exposed to any “cluster health management”, the Operator persona needed access to both “cluster health management” and the data explorer.</li>
    <li>In a similar vein, Cloud users are subject to usage-based billing, while on-prem users with their own infrastructure have license-based billing, so we needed to conditionally hide/show monetary usage rollups.</li>
  </ol>
  <p><strong>How would all of this affect our delivery date for an MVP? </strong>Upper Management suggested we hire an outside agency to design and build this 'cluster health management' 
                    dashboard, but I advised we move it to a later release instead to: </p>
                    <ol>
                      <li>Maintain consistency for the on-prem 
                    operators who accessed both sides of the product</li>
                      <li>Ensure we weren't reinventing DataDog</li>
                    </ol>
  <p>This turned 
                    out a better move than I'd expected, because the on-prem product ended up being deprecated as we focused on 
                    our cloud offering.</p>
  </div>

        <div className="image-wrap">
        <a href={mobileIa}>
                    <img style={{maxWidth:'300px',margin:'0 auto'}} src={mobileIa} alt="While the Developer persona should not be exposed to any 'cluster health management' tools, 
                    the Operator persona needed access to both 'cluster health management' and the 'data explorer'. 
                    Upper Management suggested we hire an outside agency to design and build this 'cluster health management' 
                    dashboard, but I advised we move it to a later release instead to: (1) maintain consistency for the on-prem 
                    operators who accessed both sides of the product, (2) ensure we weren't reinventing DataDog. This turned 
                    out a better move than I'd expected, because the on-prem product ended up being deprecated as we focused on 
                    our cloud offering." />
                </a>
        </div>
</section>

<section>
        <div className="text-wrap">
        <h3>
          <strong>Step 5 </strong>| Focus, Downscope
          </h3>
          <p>We decided to prioritize the experience of new users building new projects, cutting features that were only useful once you had an app in production. Some of the features moved to "Phase 2" included:</p>
          <ul>
            <li>The "Operator Persona" cluster health management console. Fauna eventually deprecated the on-prem product anyway, so this never got built.</li>
            <li>The "Large Company" database explorer. Fauna databases work like folders on your OS, so I had been designing a database explorer to navigate this folder system. However, there were technical challenges on the backend that made this a heavy lift, and most users only had a few databases anyway for Prod, Staging, QA, and Dev.</li>
            <li>The "Established Project" query log for ranking queries by cost and usage to know where to optimize. It wasn't for another 3 years that the query log finally started generating enough customer demand to become a top priority.
           </li>
          </ul>
         </div>

         <div className="image-wrap thumbnails col-of-2">
          <div className="lg-react-element">
            <a className="border" href={challenges}>
              <img src={challenges} alt="As we zeroed in on our target user, we also moved the query log to a later release. It wasn't for another 3 years that the query log finally started generating enough customer demand to become a top priority." />
            </a>
            <a className="border" href={dbExplorer}>
              <img src={dbExplorer} alt="This database explorer was also downscoped from the MVP because building it posed some technical challenges and most users only had 2-3 'layers' anyway. We never ended up needing it." />
            </a>
          </div>
  </div>

</section>

<section>
        <div className="text-wrap">
        <h3>
          <strong>Steps 6 </strong>| Source and build
          </h3>
					<p>The off-shore team finally started building out the MVP, but progress was slow, 
            so I advised we hire an internal FE dev team, including a new FE Manager since I 
            was involved in multiple other projects across the company and was getting spread 
            too thin. (I was also the hiring manager for these roles.)</p>
 
        </div>
        <div className="image-wrap">
        <a href={Th5}>
                    <img src={Th5} alt="Unfortunately, after I hired a new internal team, the webshell was also downscoped from the initial release, even though it was a key feature for our target user. Some of our users even continued to use the old dashboard (which was available on Github) until we added a webshell to the new dashboard." />
                </a>
        {/* <img style={{maxHeight:400}} src={mobileTut} alt=""/> */}

        </div>
</section>

<section>
        <div className="text-wrap">
        <h3>
          <strong>Step 7 </strong>| User test and release
          </h3>
<p>Before release, I organized several observational user testing sessions and invited the developers building the dashboard whenever possible. </p>
<p>I stepped in as Project Manager and FE Dev after the team missed the first deadline, and also helped organize marketing and documentation efforts.</p>
   <p>And in Feb 2019, we finally
released the new “Cloud Console” (later renamed "Fauna Dashboard" because users consistently confused "console" with the "Fauna Shell" CLI tool). 
</p>

</div>
        <div className="image-wrap">
        <a href={Th1}>
                    <img src={Th1} alt="We conducted a few rounds of internal and external user testing, to which I tried to invite the developers whenever possible--or at least record the sesssions for them. And in Feb 2019, we finally released the new FaunaDB Cloud Console (later renamed the Fauna Dashboard)." />
                </a>
        </div>
</section>

<section>
        <div className="text-wrap">
        <h3>
          <strong>Step 8 </strong>| Listen
        </h3>
<p>After the dashboard MVP release, I started a series of open-ended interviews to help us prioritize Phase 2. During these interviews, indexing quickly emerged as the biggest friction point for new users:</p>
<ul>
  <li>Fauna required the user to create an index before they could browse any of their data (i.e., no such thing as "table scans").</li>
  <li>This indexing could take upwards of 10 minutes, even if there was no data in the class.</li>
  <li>Indexing lacked proper documentation, so users frequently had to delete and rebuild their indexes while learning by trial-and-error.</li>
</ul>
      </div>

      <div className="image-wrap">
        <a href={indexBuilding}>
                    <img className="border" src={indexBuilding} alt="User interviews quickly surfaced indexing as the greatest friction point in onboarding: (1) There was no way to see your data without creating an index, (2) building an index could take upwards of 10 minutes, and (3) lacking proper docs, users mostly learned indexing via trial-and-error. So it could take an hour to see any of your data in your database! While fixing this the right way required changes to the API, we implemented some temporary UI band-aids as a stopgap." />
                </a>
        </div>
</section>

<section>
  <div className="text-wrap">
    <h3>
      <strong>Step 9 </strong>| Advocate & iterate
    </h3>
    <p>Because the UI was meant as a tool for learning the API, we couldn’t let the UI do any “magic” behind the scenes. It needed to match the API.</p>
    <p>Although most of the indexing usability issues would best be solved with changes to the underlying database API, we still added some temporary educational "band-aids" to the UI.</p>
    <p>Meawhile, I did a lot of user advocacy with the engineering team to help prioritize the underlying API changes:</p>
    <ul>
      <li>We made it so that small indexes build immediately.</li>
      <li>We released the Documents() function to mimic the behavior of class indexes. Now, the dashboard uses this new function to display the user's data while staying true to the API.</li>
    </ul>
 </div>

        <div className="image-wrap thumbnails row-of-2">
    <div className="lg-react-element">
      <a className="border" href={indexTip}>
        <img src={indexTip} alt="We started the backend work to make smaller indexes build immediately, and provided this 'tip' for people who didn't have class indexes." />
      </a>
      {/* <a href={indexTipDraft}>
        <img src={indexTipDraft} alt="We experimented with a few different versions of this tip during user testing." />
      </a> */}
      <a className="border" href={indexCheckbox}>
        <img src={indexCheckbox} alt="We also provided this checkbox to create a new class index during class creation, and checked it by default because the benefit of the class indexes outweighed the potential confusion caused by the class indexes appearing without the user intentionally creating them." />
      </a>
      <a href={indexBuildingWoQuote}>
        <img src={indexBuildingWoQuote} alt='But users were still confused by the presence of the class indexes (i.e., "all_*"), and it still look a long time for class indexes to build, even when there was little to no data in the class.' />
      </a>
      {/* <a href={indexIncomplete}>
        <img src={indexIncomplete} alt="" />
      </a> */}
      <a className="border" href={docsFx}>
        <img src={docsFx} alt="In the end, we (1) made small indexes build immediately; (2) introduced the Documents() function to mimic the class index, and (3) improved the documentation." />
      </a>
    </div>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3>
      <strong>Step 10 </strong>| Continue to listen, advocate, & iterate
    </h3>
    <p>In hundreds of user interviews over the next 3 years, the GUI/dashboard seldom came up unless a user was specifically asked about it. Users were much more interested in discussing the query language and APIs, 
    so that’s where I ended up investing the bulk of my efforts. </p> 
    <p>I was eventually given the title Product Manager of Community, 
    OSS, and Drivers, instead of Sr UX designer, but it’s all the same—understanding users’ problems and figuring out how to 
    improve their experiences.</p>
    <p>To help clarify this, I named my user outreach efforts the <Link to="fauna-dx">“Developer Experience Program"</Link>.</p>
 </div>
 <div className="image-wrap">
        <a href={userResearch}>
                    <img src={userResearch} alt="Users generally cared more about the UI than the API, so I became more of a Product Manager than a Designer in my role as user experience advocate." />
                </a>
        </div>
</section>


</SRLWrapper>




<section className="center prev-next">
<h3>
  <Link to="/graphic-design">
    <FontAwesomeIcon icon={faArrowLeft} />Art & Graphic Design
  </Link>
<span>|</span>
  <Link to="/fauna-dx">
    Developer Experience Program<FontAwesomeIcon icon={faArrowRight} />
  </Link>
</h3>
</section>
</main>
</>

  )
}
// }
Fauna.propTypes = {}

export default Fauna