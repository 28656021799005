import faunadb from 'faunadb'

const q = faunadb.query
const { Get, Ref, Documents, Paginate, Collection } = q

function getUserProfile(projectToFetch) {
  return Paginate(Documents(Collection("fweets")))
}

function getProjects(client) {
  return client.query(
    // Paginate(Documents(Collection("projects")))
    Get(Ref(Collection("projects"), "303641240484708931"))
    )
    // .then(res => flattenDataKeys(res))
}


export { getUserProfile, getProjects }

