import React from 'react'
import ReactDOM from 'react-dom'

import App from './app'
import SimpleReactLightbox from 'simple-react-lightbox'
// import * as serviceWorker from './service-worker'

import './fonts/GT-Walsheim-Pro-Regular.ttf'
import './fonts/GT-Walsheim-Pro-Medium.ttf'
import './fonts/GT-Walsheim-Pro-Bold.ttf'
// import './fonts/gt-walsheim-pro-regular-webfont.woff2'
// import './fonts/gt-walsheim-pro-regular-webfont.woff'
// import './../fonts/gt-walsheim-pro-medium-webfont.woff2'
// import './../fonts/gt-walsheim-pro-medium-webfont.woff'
// import './../fonts/gt-walsheim-pro-bold-webfont.woff2'
// import './../fonts/gt-walsheim-pro-bold-webfont.woff'


import './styling/index.scss'

ReactDOM.render(
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


// serviceWorker.register()
