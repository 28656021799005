import React, { useState, useEffect, useContext } from 'react'
// import { faunaQueries } from '../fauna/query-manager'
// import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
import { Teaser } from '../components/teaser'
// import LightGallery from 'lightgallery/react';
// // import styles
// // import 'lightgallery/css/lightgallery.css';
// // import 'lightgallery/css/lg-zoom.css';
// // import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// // import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';


// import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

import anc from './../images/fulls/anc.jpg?image=1';
import bocaire from './../images/fulls/bocaire2.jpg?image=2';
import vpp from './../images/fulls/vpp.png';
import checkout from './../images/fulls/gs8-checkout.jpg';
import kansas from './../images/fulls/kansas.png';
import ceCovid from './../images/fulls/ce-covid19homestudies.png';
import ceMdh from './../images/fulls/ce-mdh.png';


const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};

const WebDesign = props => {

  const [state, setState] = useState({
    fweets: [],
    loaded: false,
    error: false
  })
  const history = useHistory();

  // Fetch the fweets on first load.
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    }
  }, [user])


return (
  <>
  <header className="hero">
  <h2>Web Design/Dev</h2>
    <p>More coming soon</p>
</header>
<main className="main-content project-wrapper">

{/* <Gallery>

<Item
      original={anc}
      thumbnail={anc}
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={anc} />
      )}
    </Item>
    <Item
      original={bocaire}
      thumbnail={bocaire}
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={bocaire} />
      )}
    </Item>

    </Gallery> */}




<SRLWrapper options={options}>
    
<section>
  <div className="text-wrap">
    <h3  style={{marginBottom:'0'}}>Project websites for ProjectACT, <a href="https://covid19homestudies.org/" target="_blank">iTAP</a>, etc.</h3>  
    <p>Care Evolution | 2022</p>
    <p>Although my main job responsibility at CareEvolution has been the MyDataHelps Designer product, I help out with marketing and project work now and then.</p>
  </div>
  <div className="image-wrap" style={{textAlign:'center'}}>
    <a href={ceCovid}>
        <img src={ceCovid} alt="Although my main job responsibility at CareEvolution has been the MyDataHelps Designer product, I help out with marketing and project work now and then." />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3  style={{marginBottom:'0'}}>Ancaeus</h3>  
    <p>2017</p>
    <p>I started out as a freelance frontend developer for Ancaeus, but have since also taken their crash
								 course in Python and cybersecurity. Before you <a href="http://www.ancaeus.com" target="_blank">visit the live site</a>, please note that several 
								 changes have been made since I originally built this site in 2017, but the overall structure/feel remains the same. I built several of these 
							 parallax-style marketing websites around then.</p>
  </div>
  <div className="image-wrap">
    <a href={anc}>
        <img src={anc} alt="Security consulting company website" />
    </a>
  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Bocaire</h3>  
    <p>2016</p>
    <p>Designed and developed (JS, SASS, PHP) Wordpress Genesis child theme, configured automated email marketing campaign through Silverspring. Before you <a href="http://www.bocairecc.com" target="_blank">visit the live site</a>, please note that several 
								changes have been made since I originally built this site in 2016, but the overall structure/feel remains the same. I built several of these 
							parallax-style marketing websites around then.</p>
  </div>
  <div className="image-wrap">
    <a href={bocaire}>
        <img src={bocaire} alt="Country club website" />
    </a>

  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Official website for the band Kansas</h3>  
    <p>2015</p>
    <p>Designed and developed (JS, SASS, PHP), including integration with the Twitter API. Before you <a href="http://www.kansasband.com" target="_blank">visit the live site</a>, please note that several 
								changes have been made since I originally built this site in 2015, but the overall structure remains the same. I built several of these 
							parallax-style marketing websites around then.</p>
  </div>
  <div className="image-wrap">
    <a href={kansas}>
        <img src={kansas} alt="Official website for the band Kansas" />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Responsive landing pages for VPP</h3>  
    <p>2013-2014</p>
    <p>Designed and developed a variety of mobile-first responsive landing pages for passport expediting service.</p>
  </div>
  <div className="image-wrap">
    <a href={vpp}>
        <img src={vpp} alt="Landing pages for a passport expediting service" />
    </a>
  </div>
</section>


</SRLWrapper>
<section className="center prev-next">
  <h3>
    <Link to="/ux-ui">
      <FontAwesomeIcon icon={faArrowLeft} />Product UX/UI
    </Link>
    <span>|</span>
    <Link to="/graphic-design">
   Art & Graphic Design<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>
</main>
</>

  )
}
WebDesign.propTypes = {}

export default WebDesign