import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import SessionContext from './../context/session'
import { faunaQueries } from '../fauna/query-manager'
import { isFunction } from '../fauna/helpers/util'

const Notice = () => {
    return (
    <p className="work-in-progress">This site is a work in progress. Please excuse the rough edges.</p>
    )

  };

  
export default Notice
