import React, { useState, useEffect, useContext } from 'react'
import { faunaQueries } from '../fauna/query-manager'
import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import { safeVerifyError } from '../fauna/helpers/errors'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { SRLWrapper } from "simple-react-lightbox";

import abac from './../images/fauna-abac/fauna-abac-framed.png';
import demoData from './../images/fauna-abac/fauna-demo-data.png';
import requests from './../images/fauna-abac/fauna-abac-runas-requests.png';
import prd from './../images/fauna-abac/fauna-runas-prd.png';
import prdFile from './../images/fauna-abac/fauna-runas-prd.pdf';
import runasMov from './../images/fauna-abac/runAsPreview.mov';
import runasFinal from './../images/fauna-abac/fauna-runas.png';

const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};

const FaunaAbac = ({ match, location, props }) => {
  // // const [fweets] = useState("placeholder")
  // const [state, setState] = useState({
  //   fweets: [],
  //   loaded: false,
  //   error: false
  // })
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const history = useHistory();
  // const profileToFetch = match.params.userAlias
  // // const profileToFetch = "hi"
  // console.log("profileToFetch", profileToFetch)
  const [profileData, setProfileData] = useState(undefined)
  // async function getUserProfile () {
  //   return faunaQueries
  //     // .getUserProfile(profileToFetch)
  //     .getProjects()
  //     .then(res => {
  //       let fetchedProfile = res.data
  //       console.log(fetchedProfile)
  //       return fetchedProfile
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       // toast.error('get project failed')
  //     })
  // }

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    } else{
      // let didCancel = false
      // async function fetchData () {
      //   if (didCancel) return
      //   let fetchedProfile = await getUserProfile()
      //    setProfileData({
      //     // memeList: memeList,
      //     profileDetails: fetchedProfile
      //   })
      //   //profileData is still undefined the first time this useEffect runs
      // }
      // fetchData()
      // return function () {didCancel = true}
    }
  }, [])

  // return renderProfile()
  // function renderProfile () {
    // if (profileData === undefined) return (<React.Fragment><h1>Loading ... </h1></React.Fragment>)
    // let thisProject = profileData.profileDetails
    // console.log("thisProject", thisProject)

  return (
    <>

    <header className="hero">
    <h1>Database Access Control</h1>
    {/* <h5>2018-2021</h5> */}
    <p>April 2018 - 2021</p>
    <h3>A developer-centric authorization system</h3>
<p>Pretty much anyone building an app needs some kind of authorization model, and they start exploring it right away when evaluating a new database product. </p>

  </header>
    <main className="main-content project-wrapper">
    <SRLWrapper options={options}>


      <section>
        <div className="text-wrap">
          <h3>Designing Fauna's attribute-based-access-control (ABAC)</h3>
          <p><a href="https://www.figma.com/proto/gpEr3BwmVaTh4uixnjUepa/portfolio-prototypes?node-id=3%3A691&scaling=min-zoom&page-id=2%3A25&starting-point-node-id=3%3A691" target="_blank">interactive prototype</a></p>  
          <p>This was one of the more challenging features I've ever had to design. Like many developer products, 
            the API itself falls too far on the “flexibility” side of the flexibility/usability tradeoff. However, the GUI
             still needs to match the underlying API since that’s what users primarily interact 
      with once they outgrow the training wheels of the dashboard.
</p>     </div>
        <div className="image-wrap">
          <a href={abac}>
            <img src={abac} alt="Fauna's ABAC system was one of the more challenging features I've ever had to design. Like many developer products, 
            the API itself falls too far on the “flexibility” side of the flexibility/usability tradeoff. However, the GUI
             still needs to match the underlying API since that’s what users primarily interact 
      with once they outgrow the training wheels of the dashboard. (Close this gallery for link to prototype.)" />
          </a>
        </div>
      </section>

      <section>
        <div className="text-wrap">
          <h3>Learning features</h3>  
          <p>Since the Dashboard UI is mainly a learning and debugging tool, with advanced users working directly with the API,
           we also included some learning features like:</p>
          <ul>
            <li>Adding some custom ABAC roles and Uder-defined functions to the optional demo data</li>
            <li>We already had the “FQL” tab that translates the user's UI selections directly into FQL (the database query language), so we made this editable.</li>
          </ul>
          <p>That said, we had plans to add more educational context to this UI feature, but it was scoped out of the MVP.</p>
        </div>
        <div className="image-wrap">
          <a href={demoData}>
            <img className="border" src={demoData} alt="We already had an option for beginners to pre-populate their database with fake data, so it was an easy lift to add some custom ABAC roles and user-defined functions to this demo data. I actually made this PR myself instead of piling it onto the dev queue." />
          </a>
        </div>
      </section>


      <section>
        <div className="text-wrap">
          <h3>But what about debugging?</h3>  
          <p>Unfortunately, the feature that would most help with debugging ABAC—a <strong>webshell impersonator</strong>—also got scoped out of the MVP. And, as expected, users lamented how difficult it was to debug ABAC. An impersonator became the most commonly requested feature in the webshell. </p>
        </div>
        <div className="image-wrap">
          <a href={requests}>
              <img src={requests} alt="Unfortunately, the feature that would most help with debugging ABAC—a webshell impersonator—got scoped out of the MVP. 
              And, as expected, users lamented how difficult it was to debug ABAC. An impersonator became the most commonly requested feature in the webshell." />
          </a>
        </div>
      </section>


      <section>
        <div className="text-wrap">
          <h3>Making a case for the impersonator</h3>  
          <p><a target="_blank" href={prdFile}>mini prd (pdf)</a></p>
          <p>When I was officially promoted from UX Designer to PM, the webshell impersonator was one of the first features I advocated for. But the rest of the PM team assumed that either ABAC was a feature for advanced users, or that only a complete redesign could save ABAC.
            To help justify the priority of this impersonation feature, I wrote a “mini PRD” that provided enough technical background to fully understand the problem, and explained how even basic use cases require ABAC. </p>
        </div>
        <div className="image-wrap">
        <a href={prd}>
            <img src={prd} alt="When I was officially promoted from UX Designer to PM, I collected user feedback from the DX Program into a PRD to help advocate that the impersonator/RunAs
             feature be reprioritized. This PRD was unusual in the amount of technical background it provided for such a small feature. (Close this gallery for link to 'mini PRD'.)" />
          </a>
        </div>
      </section>


      <section>
        <div className="text-wrap">
          <h3>Developing a proof of concept</h3>  
          <p>After getting buy-in, it only took a few days before a proof-of-concept was ready for user testing. </p>
          <p>As with most features I designed or PMed at Fauna, I wrote the user testing instructions, which were also used as a jumping off point for the feature's documentation.</p>
        </div>
        <div className="image-wrap">
        <video className="border" controls="controls" style={{width:"100%"}} name="Video Name" src={runasMov}>
          </video>
 
        </div>
      </section>



      <section>
        <div className="text-wrap">
          <h3>Measuring success</h3>  
          <p><a href="https://www.figma.com/proto/gpEr3BwmVaTh4uixnjUepa/portfolio-prototypes?node-id=2%3A6&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=2%3A22&show-proto-sidebar=1" target="_blank">interactive prototype</a></p>
          <p>Preview testing uncovered some minor changes to add before release, like token impersonation and remembering the 
            impersonation settings when navigating to other dashboard tabs. </p>
            <p>But overall, preview testers were very enthusiastic and user complaints about
             ABAC started to dwindle after release.</p>
      </div>
        <div className="image-wrap">
          <a href={runasFinal}>
            <img className="border" src={runasFinal} alt="User complaints about
             ABAC started to dwindle after we released the impersonator. (Close this gallery for link to video and prototype.)" />
          </a>
        </div>
      </section>


</SRLWrapper>

<section className="center prev-next">
  <h3>
    <Link to="/fauna">
      <FontAwesomeIcon icon={faArrowLeft} />Database Management System
    </Link>
    <span>|</span>
    <Link to="/fauna-docs">
     Database Docs<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>
</main>
</>

  )
}
// }
FaunaAbac.propTypes = {}

export default FaunaAbac