import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from './pages/home'
import Login from './pages/login'
import Contact from './pages/contact'
import Fauna from './pages/fauna'
import FaunaDocs from './pages/fauna-docs'
import FaunaAbac from './pages/fauna-abac'
import FaunaDx from './pages/fauna-dx'
import CS from './pages/commerce-signals'
import Gs8hawl from './pages/gs8hawl'
import WebDesign from './pages/web-design'
import GraphicDesign from './pages/graphic-design'
import UxUi from './pages/ux-ui'
import Layout from './components/layout'
import { useHistory } from 'react-router-dom'
import { SessionProvider, sessionReducer } from './context/session'

const App = () => {
  const [state, dispatch] = React.useReducer(sessionReducer, { user: null })
  const { user } = state

  const loadScript = url => {
    const script = document.createElement('script')
    script.async = true
    script.src = url
    document.head.appendChild(script)
  }

  // useEffect(() => {
  //   // Load all cloudinary scripts
  //   loadScript('https://widget.cloudinary.com/v2.0/global/all.js')
  // }, [])

  // Return the header and either show an error or render the loaded profiles.
  return (
    <React.Fragment>
      <Router>
        <SessionProvider value={{ state, dispatch }}>
          <Layout>
            <Switch>
              <Route exact path="/accounts/login">
                <Login />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              
              {/* <Route path="/fauna" component={Fauna} />  */}
              <Route path="/contact/" component={Contact} />
              {user ? <Route path="/projects/fauna" component={Fauna} /> : null} 
              {user ? <Route path="/fauna" component={Fauna} /> : null} 
              {user ? <Route path="/commerce-signals" component={CS} /> : null} 
              {user ? <Route path="/gs8-waitlist" component={Gs8hawl} /> : null} 
              {user ? <Route path="/fauna-abac" component={FaunaAbac} /> : null} 
              {user ? <Route path="/fauna-dx" component={FaunaDx} /> : null} 
              {user ? <Route path="/fauna-docs" component={FaunaDocs} /> : null} 
              {user ? <Route path="/web-design-dev" component={WebDesign} /> : null} 
              {user ? <Route path="/graphic-design" component={GraphicDesign} /> : null} 
              {user ? <Route path="/ux-ui" component={UxUi} /> : null} 
              {/* {user ? <Route exact path="/projects/fauna/:userAlias" component={Fauna} /> : null} */}


              {/* <Route path="/users/:authorAlias" component={User} /> */}
              {/* <Route path="/tags/:tag" component={Tag} /> */}
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Layout>
        </SessionProvider>
      </Router>
    </React.Fragment>
  )
}

export default App
