import { flattenDataKeys } from '../helpers/util'
import { CreateHashtags } from './../queries/hashtags'
import { AddRateLimiting } from './../queries/rate-limiting'

const faunadb = require('faunadb')
const q = faunadb.query
const {
  Call,
  Create,
  Collection,
  Identity,
  Paginate,
  Documents,
  Lambda,
  Get,
  Ref,
  Var,
  Select,
  Let,
  Match,
  Index,
  Join,
  If,
  Exists,
  Update,
  Do,
  Add,
  Subtract,
  Not,
  Contains,
  Abort,
  Now
} = q


// The previous example was ok, but all_fweets just gets fweets in the order
// that they were posted. Of course we want to see fweets from our followers
// and preferably according to their popularity and how old they are.
// We have a collection called followerstats, a relation between
// a follower and an author which keeps how popular that author is to that specific follower.
// We have built an index ('followerstats_by_user_popularity') using bindings (see ./../setup/followers) that calculates
// a measure of 'popularity' and de decays it by incorporating the unix start timestamp.
function GetFweets(client) {
  // const FQLStatement = GetFweetsWithUsersMapGetGeneric(
  //   // Since we start of here with followerstats index (a ref we don't need afterwards, we can use join here!)
  //   q.Map(
  //     Paginate(
  //       Join(
  //         // the index takes one term, the user that is browsing our app
  //         Match(Index('followerstats_by_user_popularity'), Select(['data', 'user'], Get(Identity()))),
  //         // Join can also take a lambda,
  //         // and we have to use a lambda since our index returns more than one variable.
  //         // Our index again contains two values (the score and the author ref), so takes an array of two values
  //         // We only care about the author ref which we will feed into the fweets_by_author index,
  //         // to get fweet references. Added advantage, because we use a join here we can let the index sort as well ;).
  //         Lambda(['fweetscore', 'authorRef'], Match(Index('fweets_by_author'), Var('authorRef')))
  //       )
  //     ),
  //     // the created time has served its purpose for sorting.
  //     Lambda(['createdtime', 'ref'], Var('ref'))
  //   )
  // )

  // // Getting fweets is a moderately heavy operation.
  // // To discourage that people start 'crawling' fwitter, we can rate-limit it.
  // // Reads will be charged since Fauna reads optimistically
  // // still help though to discourage people to start crawling your API.
  // return AddRateLimiting('get_fweets', FQLStatement, Identity())
}

function getFweets(client) {
  return client.query(
    // Paginate(Documents(Collection("projects")))
    Get(Ref(Collection("projects"), "303641240484708931"))
    )
    // .then(res => flattenDataKeys(res))
}




export {
  // createFweet,
  // CreateFweet,
  // createFweetWithoutUDF,
  // GetFweets,
  getFweets,
  // GetFweetsByTag,
  // getFweetsByTag,
  // GetFweetsByAuthor,
  // getFweetsByAuthor,
  // LikeFweet,
  // likeFweet,
  // Refweet,
  // refweet,
  // Comment,
  // comment
}
