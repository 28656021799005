import React, { useState, useEffect, useContext } from 'react'
// import { faunaQueries } from '../fauna/query-manager'
// import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import LightGallery from 'lightgallery/react';
// // import styles
// // import 'lightgallery/css/lightgallery.css';
// // import 'lightgallery/css/lg-zoom.css';
// // import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// // import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';


import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import info1 from './../images/fulls/infolot.jpg';
import info2 from './../images/fulls/infosil.jpg';
import info3 from './../images/fulls/info1.jpg';
import art01 from './../images/fulls/art-fa.jpg';
import art02 from './../images/fulls/art-apt.jpg';
import art03 from './../images/fulls/art-bottomless1.jpg';
import art04 from './../images/fulls/art-bottomless2.jpg';
import art05 from './../images/fulls/art-embed.jpg';
import art06 from './../images/fulls/art-infiltrate.jpg';
import art07 from './../images/fulls/art-head.jpg';
import art08 from './../images/fulls/art-face.jpg';
import gs8var from './../images/fulls/gs8-var.jpg';

const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};

const GraphicDesign = props => {

  const [state, setState] = useState({
    fweets: [],
    loaded: false,
    error: false
  })
  const history = useHistory();

  // Fetch the fweets on first load.
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    }
  }, [user])

return (
  <>
  <header className="hero">
  <h2>Art & Graphics</h2>
    {/* <p>GoSection8 already had a monopoly over the Section8 rental market through their strong relationships with housing authorities all over the country. While some revenue came from a subscription-based service for property owners, most of their revenue came from ad impressions.
</p><p>
When the call center told the CEO that the #1 question they got was “How do I apply for Section 8?” He realized the amount of revenue they could generate from creating a centralized platform for low-income families from all over the country to apply for Section8 housing. 
</p>
    <ul className="information">
        <li><span>Technologies: </span>Axure, KnockoutJS/CSS/HTML, .NET</li>
    </ul> */}
</header>
<main className="main-content project-wrapper">
<SRLWrapper options={options}>

{/* <section style={{flexDirection:'column', padding:'2rem'}}>
</section>
<section>
<img src={planning} />
</section> */}

<section>
  <div className="text-wrap">
    <h3>Art & Architecture</h3>  
    <p>I have some background in architecture and fine arts (drawing, photography, history, theory).</p>
  </div>
  {/* <div className="image-wrap">
    <a href={art}>
        <img src={art} alt="Architectural drawings" />
    </a>
  </div> */}
  <div className="image-wrap thumbnails">
          <div className="lg-react-element">
                <a href={art01}>
                    <img src={art01}  alt="" />
                </a>
                {/* <a href={art02}>
                    <img src={art02}  alt="" />
                </a> */}
                      <a href={art07}>
                    <img src={art07}  alt="" />
                </a>
                <a href={art03}>
                    <img src={art03}  alt="" />
                </a>
                {/* <a href={art04}>
                    <img src={art04}  alt="" />
                </a> */}
                {/* <a href={art05}>
                    <img src={art05}  alt="" />
                </a> */}
                {/* <a href={art06}>
                    <img src={art06}  alt="" />
                </a> */}
          
                {/* 
                <a href={art08}>
                    <img src={art08}  alt="" />
                </a> */}
          </div>

        {/* </SRLWrapper> */}

  </div>
</section>

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Graphic Design</h3>  
    <p>2016-2017</p>
    <p> Assorted print and design projects for GoSection8.</p>
  </div>
  <div className="image-wrap">
    <a href={gs8var}>
        <img src={gs8var} alt="Various graphic design work for GoSection8" />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Infographics (1/3)</h3>  
    <p>2015-2016</p>  
    <p>Infographics and creatives based on copy provided by Bankrate.com's editorial team.</p>
  </div>
  <div className="image-wrap">
    <a href={info1}>
        <img src={info1} alt="Infographics" />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Infographics (2/3)</h3>    
    <p>2015-2016</p>
    <p>Infographics and creatives based on copy provided by Bankrate.com's editorial team.</p>
  </div>
  <div className="image-wrap">
    <a href={info2}>
        <img src={info2} alt="Infographics" />
    </a>
  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Infographics (3/3)</h3>  
    <p>2015-2016</p>
    <p>Infographics and creatives based on copy provided by Bankrate.com's editorial team.</p>
  </div>
  <div className="image-wrap">
    <a href={info3}>
        <img src={info3} alt="Infographics" />
    </a>
  </div>
</section>




</SRLWrapper>
<section className="center prev-next">
  <h3>
    <Link to="/web-design-dev">
      <FontAwesomeIcon icon={faArrowLeft} />Web Design & Development
    </Link>
    <span>|</span>
    <Link to="/fauna">
    Database Management System<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>
</main>
</>

  )
}
GraphicDesign.propTypes = {}

export default GraphicDesign