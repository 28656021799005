import React, { useState, useEffect, useContext } from 'react'
import { faunaQueries } from '../fauna/query-manager'
import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
import { safeVerifyError } from '../fauna/helpers/errors'
// import LightGallery from 'lightgallery/react';
// // import styles
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';
// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

import { SRLWrapper } from "simple-react-lightbox";


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import codeStyling from './../images/fulls/fauna-docs-code-styling.png';
import docs2018 from './../images/fulls/fauna-docs-2018.png';
import docs2018p1 from './../images/fulls/fauna-docs-2018-p1.png';
import docs2018p2 from './../images/fulls/fauna-docs-2018-p2.png';
import docs2018p3 from './../images/fulls/fauna-docs-2018-p3.png';
import docs2018p4 from './../images/fulls/fauna-docs-2018-p4.png';
import docs2021 from './../images/fulls/fauna-2021-ia.png';
import qsNew from './../images/fulls/fauna-qs-top.png';
import docsAnalytics from './../images/fulls/fauna-docs-qs-analytics.png';
import mobileTut from './../images/fulls/fauna-mobile-tut-2.png';
import codeQuote from './../images/fulls/fauna-code-quote.png';
import azQuote from './../images/fulls/fauna-az-quote.png';
import fqlQuote from './../images/fulls/fauna-fql-quote.png';
import drivers from './../images/fulls/fauna-docs-drivers.png';
import asideExpanded from './../images/fulls/fauna-docs-aside-expanded.png';
import docsQs from './../images/fulls/fauna-docs-framed.png';


const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};


const FaunaDocs = ({ match, location, props }) => {
  // // const [fweets] = useState("placeholder")
  // const [state, setState] = useState({
  //   fweets: [],
  //   loaded: false,
  //   error: false
  // })
  // const onInit = () => {
  //   console.log('lightGallery has been initialized');
  // };

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const history = useHistory();
  // const profileToFetch = match.params.userAlias
  // // const profileToFetch = "hi"
  // console.log("profileToFetch", profileToFetch)
  // const [profileData, setProfileData] = useState(undefined)
  // async function getUserProfile () {
  //   return faunaQueries
  //     // .getUserProfile(profileToFetch)
  //     .getProjects()
  //     .then(res => {
  //       let fetchedProfile = res.data
  //       console.log(fetchedProfile)
  //       return fetchedProfile
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       // toast.error('get project failed')
  //     })
  // }

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    } else{
      // let didCancel = false
      // async function fetchData () {
      //   if (didCancel) return
      //   let fetchedProfile = await getUserProfile()
      //    setProfileData({
      //     // memeList: memeList,
      //     profileDetails: fetchedProfile
      //   })
      //   //profileData is still undefined the first time this useEffect runs
      // }
      // fetchData()
      // return function () {didCancel = true}
    }
  }, [])

  // return renderProfile()
  // function renderProfile () {
    // if (profileData === undefined) return (<React.Fragment><h1>Loading ... </h1></React.Fragment>)
    // let thisProject = profileData.profileDetails
    // console.log("thisProject", thisProject)

  return (
    <>
    <header className="hero">
    <h1>Database Docs</h1>
    {/* <h5>2018-2021</h5> */}
    <h3>AKA Diplomacy 101</h3>
    <p>April 2018 - April 2021</p>
{/* <div style={{textAlign:"right"}}>- user quote from general Fauna feedback session</div> */}

  </header>
    <main className="main-content project-wrapper">

    <SRLWrapper options={options}>

<section>
  <div className="text-wrap">
<h3>Work with what you've got</h3>
<p>When I started at FaunaDB in 2018, the docs welcomed users with a page titled "Overview of Introductions", lacked a quickstart, barely had an API reference, etc.</p>
<p>And, over the next 3 years, the documentation was consistently one of the most challenging products I worked on, because (1) various stakeholders had such wildly different opinions on how the docs should be organized, and (2) we never had all of the content we needed to make anyone's "ideal information architecture (IA)" a reality.</p>
<p>Only after I collected a toolbox of qualitative and quantitative user data did the docs become easier to work on.</p>
  </div>
  <div className="image-wrap thumbnails">
          <div className="lg-react-element">
          <a href={docs2018}>
                    <img src={docs2018} alt="The April 2018 docs' nav was difficult to interpret, because there was no way to see all of the sub-sections simultaneously. You could only see which pages were in a section after drilling into a page in the section. And I was told that the 'Overview of Introductions' page existed because of the way it was coded: every section was required to have an overview page." />
                </a>
                <a href={docs2018p1}>
                    <img src={docs2018p1}  alt="The first thing I did was recode the navbar so I could (1) actually see what kind of content we had to work with, (2) replace the 'Overview of Introductions' page with a proper Quickstart."  />
                </a>
                <a href={docs2018p2}>
                    <img src={docs2018p2}  alt="Notes from the first docs IA meeting, with the CTO and techical writer. Our priorities were to (1) Remove some of the 'filler' pages; (2) Flesh out the API Reference; (3) Separate content by user persona and product, moving operations into its own section for people using the on-prem product." />
                </a>
                <a href={docs2018p3}>
                    <img src={docs2018p3}  alt="Wireframe: We rewrote the Quickstart to use the CLI I was designing." />
                </a>
                <a href={docs2018p4}>
                    <img src={docs2018p4}  alt="Wireframe: We wanted to bring the REPL into the docs, but this was scoped out of the MVP" />
                </a>
                <a href={docs2021}>
                    <img src={docs2021}  alt="Revisiting the docs IA in 2021 to solve some of the primary problems expressed in user testing: (1) no 'golden path' to building an app, (2) too much copy, but not enough conceptual information, and (3) no good reasons to learn FQL." />
                </a>
          </div>
  </div>
</section>


<section>
  <div className="text-wrap">
<h3>2021 Quickstart Revamp</h3>
<p>In 2021, I finally collected enough data to warrant writing a new quickstart to replace the one I had written back in 2018. The new quickstart was written specifically to help address the most common problems identified during user feedback sessions:</p>
<ol>
  <li>Users did not have a "golden path" to get started building an app.</li>
  <li>Users were not emotionally motivated to learn FQL.</li>
  <li>Users wanted to see fewer words, while also wanting more conceptual information.</li>
  <li>Users thought they had to choose between GraphQL and FQL, not realizing that the two were meant to be used together.</li>
</ol>

{/* <ul>
  <li><a href="https://docs.google.com/document/d/1RQbtWCUltyw_bKFJ_KrIa69EAxJIs3M4GQk6QWZwN2o/edit#">Problem space/PRD</a></li>
<li><a href="https://docs.google.com/document/d/1_l5sFhM_rtc839OOHnxbis4xNhNPTCvNlkDjvoaPnus/edit">Proposed IA changes</a></li>
<li><a href="https://www.figma.com/proto/lr9C7JTpsB8sON4sEAQC7m/docs-2020-q3?node-id=131%3A135&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=131%3A135">New Quickstart Prototype</a>
</li> 
</ul>*/}
 </div>
  <div className="image-wrap">
          <a href={codeQuote}>
                    <img className="border" src={codeQuote} alt="The first 'What is FaunaDB?' page repeated a lot of info from the marketing site, and the 'Getting started' page was about how to use the docs--not how to use FaunaDB. But users don't visit the docs just to learn about Fauna; they arrive here trying to build an app." />
                </a>

  </div>
</section>

<section>
  <div className="text-wrap">
  <h3>1. Creating a "golden path"</h3>
  <p><strong>Problem: </strong>Users often complained that they didn’t know where to start, ended up with multiple tabs open, 
    and still couldn’t find what they needed. Some users couldn't even figure out how to install the FaunaDB driver 
    in their app.</p>
    <p><strong>Solution/s:</strong></p>
    <ul>
      <li>Move the driver installation instructions and sample apps into the getting started section so that users can do what they came here to do: build an app.</li>
      <li>Replace the marketing-oriented introductory material with the Quickstart so that the user sees code they can copy/paste as soon as possible with the fewest clicks.
      </li>
    </ul>
    <p>This also increased conversions from the docs since signing up is the first step in the QuickStart.</p>
  </div>
  <div className="image-wrap   thumbnails row-of-2">
       
  <a href={docsQs}>
                    <img src={docsQs} alt="I (controversially) moved the material on those two introductory pages elsewhere, making the new Quickstart the docs home page. This was predicted to increase conversions since signing up was the first step in the QuickStart." />
                </a>
   
    <a href={drivers} style={{padding:"1rem" }} >
        <img className="border" src={drivers} alt="Users often complained that they didn’t know where to start, ended up with multiple tabs open, 
    and still couldn’t find what they needed. Some users couldn't even figure out how to install the driver. So we also moved the driver instructions and sample apps into the 'Getting started' section, immediately after the new Quickstart." />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
<h3>2. Selling a functional query language</h3>
<p><strong>Problem: </strong>Counterintuitively, many frontend users without previous database experience got over the FQL learning curve faster, especially if they wrote functional JavaScript. It wasn’t as much of a paradigm shift for them because they didn’t have a paradigm like SQL to shift from. They were just learning how to query a database.
</p>
<p><strong>Solution/s: </strong>We were always talking about rewriting FQL or covering it up with a SQL API, but the development effort was too high and converting it to SQL would actually rob FQL of some of the features that made it so powerful. So, as an interim solution, I used the new QuickStart to try “selling” FQL, helping users want to learn it.</p>
</div>
  <div className="image-wrap">
  <div>
    <a href={fqlQuote}>
        <img className="border" src={fqlQuote} alt="Counterintuitively, many frontend users without previous database experience got over the FQL learning curve
         faster, especially if they wrote functional JavaScript. It wasn’t as much of a paradigm shift for them because they didn’t have
          a paradigm like SQL to shift from. They were just learning how to query a database, and actually loved FQL. So, one of the goals of the new Quickstart was to try to 'sell' FQL to users who already knew other query languages." />
    </a>
  </div>
  </div>
</section>

<section>
  <div className="text-wrap">
<h3>3. Balancing concepts with code</h3>
<p><strong>Problem: </strong>The same users who already think the docs are "too wordy" also ask for more conceptual explanation without having to open so many tabs. While it’s true that separating conceptual information into its own section is a usability anti-pattern, merging this material into the Getting Started material would add "more words" for those users who already think there are too many and just want to see code. </p>
<p><strong>Solution: </strong>To help solve this problem, I redesigned and rewrote some of the introductory material to be much shorter, but with expandable “asides” that users could optionally expand if they wanted more conceptual information.</p>
</div>
  <div className="image-wrap">
  <div>
    <a href={asideExpanded}>
        <img className="border" src={asideExpanded} alt="The same users who said that the docs were 'too wordy' also sometimes asked for more conceptual explanation. So I rewrote the introductory material to be much shorter, but with expandable “asides” that users could optionally expand if they wanted more. Here's an example of a conceptual aside that also helps sell FQL." />
    </a>
  </div>
  </div>
</section>

<section>
  <div className="text-wrap">
<h3>Results of the 2021 Quickstart revamp</h3>
<p>Overall docs exit % significantly decreased from around 20% to 16%, and the number of pageviews and average time on page signficantly increased for the intro page.</p>
 </div>
  <div className="image-wrap">
  {/* <SRLWrapper options={options}> */}
          <div>
          <a href={docsAnalytics}>
                    <img className="border" src={docsAnalytics} alt="Overall docs exit % significantly decreased from around 20% to 16%, and the number of pageviews and average time on page signficantly increased for the intro page." />
                </a>
   
          </div>

  </div>
</section>



</SRLWrapper>

<section className="center prev-next">
  <h3>
    <Link to="/fauna-abac">
      <FontAwesomeIcon icon={faArrowLeft} />Database Access Control
    </Link>
    <span>|</span>
    <Link to="/gs8-waitlist">
    Centralized Section 8 Waitlist<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>

</main>
</>

  )
}
// }
FaunaDocs.propTypes = {}

export default FaunaDocs