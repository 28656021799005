import React, { useState, useEffect, useContext } from 'react'
import { faunaQueries } from '../fauna/query-manager'
import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import { safeVerifyError } from '../fauna/helpers/errors'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { SRLWrapper } from "simple-react-lightbox";

import userResearch from './../images/fulls/fauna-user-research.png';
import results from './../images/fulls/fauna-roadmap-results.png';
import forums from './../images/fulls/fauna-forums.png';
import drivers from './../images/fulls/fauna-driver-usage.png';
import dropoff from './../images/fulls/fauna-gql-dropoff.png';
import meme from './../images/fulls/meme.png';
import diligence from './../images/fulls/diligence.png';

const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};

const FaunaDx = ({ match, location, props }) => {
  // // const [fweets] = useState("placeholder")
  // const [state, setState] = useState({
  //   fweets: [],
  //   loaded: false,
  //   error: false
  // })
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const history = useHistory();
  // const profileToFetch = match.params.userAlias
  // // const profileToFetch = "hi"
  // console.log("profileToFetch", profileToFetch)
  const [profileData, setProfileData] = useState(undefined)
  // async function getUserProfile () {
  //   return faunaQueries
  //     // .getUserProfile(profileToFetch)
  //     .getProjects()
  //     .then(res => {
  //       let fetchedProfile = res.data
  //       console.log(fetchedProfile)
  //       return fetchedProfile
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       // toast.error('get project failed')
  //     })
  // }

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    } else{
      // let didCancel = false
      // async function fetchData () {
      //   if (didCancel) return
      //   let fetchedProfile = await getUserProfile()
      //    setProfileData({
      //     // memeList: memeList,
      //     profileDetails: fetchedProfile
      //   })
      //   //profileData is still undefined the first time this useEffect runs
      // }
      // fetchData()
      // return function () {didCancel = true}
    }
  }, [])

  // return renderProfile()
  // function renderProfile () {
    // if (profileData === undefined) return (<React.Fragment><h1>Loading ... </h1></React.Fragment>)
    // let thisProject = profileData.profileDetails
    // console.log("thisProject", thisProject)

  return (
    <>

    <header className="hero">
    <h1>FaunaDB DX Program</h1>
    {/* <h5>2018-2021</h5> */}
    <p>April 2018 - 2021</p>
    <h3>When you’re missing several “table stakes" features, how do you decide what to build first? 
</h3>
  </header>
    <main className="main-content project-wrapper">

    <SRLWrapper options={options}>
    <section>
        <div className="text-wrap">
          <h3>Genesis of the Developer Experience (DX) program: User interviews</h3>  
          <p>After releasing the dashboard MVP (see <Link to="/fauna">Database Management System</Link>), we needed 
          to prioritize phase 2, so I conducted a series of open-ended product feedback interviews and coded them using 
          traditional qualitative research methods so as to avoid as much internal bias as possible.
</p><p>From these coded interview transcripts, I then developed the first roadmap survey.</p>
        </div>
        <div className="image-wrap">
          <a href={userResearch}>
            <img src={userResearch} alt="After releasing the dashboard MVP, I conducted a series of open-ended product feedback interviews--mostly recruiting from the Slack Community I had recently started (and eventually grew to 18k). I then coded the interview transcripts using traditional qualitative research methods to help avoid internal bias, and used this coded qualitative data to generate survey content--most notably the first roadmap survey." />
          </a>
        </div>
      </section>

      <section>
        <div className="text-wrap">
          <h3>The first roadmap survey</h3>  
          <p>The first roadmap survey asked community members to rank a list of features from “irrelevant” to “mandatory”.</p>   
        <p>These survey results were then presented to the board of directors to help explain why we were pursuing streaming instead of a SQL API. </p>
          <p>Although the survey had "survivor bias", streaming was still a better product-market fit for us, because SQL 
            appeals to more enterprise-level customers and we had recently pivoted our entire go-to-market strategy to be more "developer-focused", targeting other startups who could grow with us.</p>
        </div>
        <div className="image-wrap">
          <a href={results}>
            <img src={results} alt="These roadmap survey results were presented to the board of directors to help explain our product-market fit, and why we were pursuing streaming instead of a SQL API." />
          </a>
        </div>
      </section>


      <section>
        <div className="text-wrap">
          <h3>Community Development</h3>  
          <p>I originally started the Slack community so that I’d have users to interview
             for product feedback, but it had 18k members by the time I left (the Slack was then moved to Discord after I left).</p>
            <p>I also built out and monitored the forums, working closely with Support and Developer Advocacy to maintain community support SLAs.</p>
            <p>And finally, as PM of OSS, I managed all of our officially supported OSS projects like the language drivers, CLI, and VSCode extension.</p>
              </div>
        <div className="image-wrap">
          <a href={forums}>
            <img src={forums} alt="After growing the Slack community from 0 to 18k members, I needed to transition users to a forum where Q&A would be discoverable via Google to non-Slack users. The rest of the product team also requested we transition from the roadmap surveys to a 'feature requests' category in the forums, but it was not as successful as the surveys because (1) response rate was lower, and (2) some level of curation is really useful when you have limited resources. Last time I looked at the forums, the most requested roadmap feature was a public roadmap." />
          </a>
        </div>
      </section>

      <section>
        <div className="text-wrap">
          <h3>Tracking API usage</h3>  
          <p>The GUI/Dashboard is primarily a tool for learning, monitoring, and debugging. Profitable usage comes from the API/drivers. </p>
          <p>So we developed a way to track the number of queries each user ran through different drivers and tools.</p>
          <p>Among other things, this data demonstrated that GraphQL dropoff was more dramatic than that of any of the other APIs and tools. </p>
            <p>Those of us who were active in the community already suspected this, but having it quantified made it easier to get it prioritized.</p>
       </div>
        <div className="image-wrap">
          <a href={drivers}>
            <img src={drivers} alt="The GUI/Dashboard is primarily a tool for learning, monitoring, and debugging. Profitable usage comes from the API/drivers. So we developed a way to track the number of queries each user ran through different drivers and tools. Among other things, this data demonstrated that GraphQL dropoff was more dramatic than that of any of the other APIs and tools. Those of us who were active in the community already suspected this, but having it quantified made it easier to get it prioritized." />
          </a>
        </div>
      </section>



      <section>
        <div className="text-wrap">
          <h3>Diligence</h3>  
          <p>By the time I left, we’d recorded and transcribed hundreds of interviews, conducted several surveys, and set up multiple systems 
            for tracking and monitoring behavior in more quantitative ways.</p>
         <p>When new Fauna employees onboarded, they often consulted these resources first, and whenever we started a new PRD, we could search
           the transcripts in Google Drive to find user stories. We also used these resources to find users for follow-up interviews and user testing.</p>
      </div>
        <div className="image-wrap">
          <a href={diligence}>
            <img src={diligence} alt="By the time I left, we’d recorded and transcribed hundreds of interviews, conducted several surveys, and set up multiple systems 
            for tracking and monitoring behavior in more quantitative ways. When new Fauna employees onboarded, they often consulted these resources first, and whenever we started a new PRD, we could search
            the transcripts in Google Drive to find user stories. We also used these resources to find users for follow-up interviews and user testing. These were the results from a GraphQL-specific roadmap survey." />
          </a>
        </div>
      </section>

      <section>
        <div className="text-wrap">
          <h3>DX Culture</h3>  
          <p>The most important outcome of Fauna's Developer Experience (DX) program was cultivating a company culture around DX.</p> 
    <p>At some companies, this means caring about users' needs, but still prioritizing projects based on whatever the PMs intuit would best serve those needs.</p>
<p>In contrast, Fauna’s DX culture involved diligently recording non-paraphrased user feedback (because <strong>paraphrasing often becomes more 
  solution-focused, losing sight of the original problem</strong>) in an organized and transparent way, and then using those quotes combined with
   quantitative data to help drive the roadmap.
   </p> </div>
        <div className="image-wrap">
          <a href={meme}>
            <img src={meme} alt="The most important outcome of Fauna's Developer Experience (DX) program was cultivating a company culture around DX. At Fauna, this meant diligently recording non-paraphrased user feedback (because paraphrasing often becomes 
  solution-focused, losing sight of the original problem) in an organized and transparent way, and then using this qualitative data to better color our
   quantitative data and help drive the roadmap." />
          </a>
        </div>
      </section>

</SRLWrapper>


<section className="center prev-next">
<h3>
  <Link to="/fauna">
    <FontAwesomeIcon icon={faArrowLeft} />Database Management System
  </Link>
  <span>|</span>
  <Link to="/fauna-abac">Database Access Control<FontAwesomeIcon icon={faArrowRight} />
  </Link>
</h3>
</section>
</main>
</>

  )
}
// }
FaunaDx.propTypes = {}

export default FaunaDx