import React, { useState, useEffect, useContext } from 'react'
// import { faunaQueries } from '../fauna/query-manager'
// import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
// import LightGallery from 'lightgallery/react';
// // import styles
// // import 'lightgallery/css/lightgallery.css';
// // import 'lightgallery/css/lg-zoom.css';
// // import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// // import plugins if you need
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';


import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import rosetta01 from './../images/fulls/ce-rosetta-lp.png';
import rosetta02 from './../images/fulls/ce-rosetta-db.png';
import rosetta03 from './../images/fulls/ce-rosetta-shell.png';
import rosetta04 from './../images/fulls/ce-rosetta-inbound.png';
import rosetta05 from './../images/fulls/ce-rosetta-outbound.png';
import rosetta06 from './../images/fulls/ce-rosetta-mobile.png';

import checkout from './../images/fulls/gs8-checkout.jpg';
import sampleapp from './../images/fulls/fauna-sampleapp-tut.png';
import bankrate from './../images/fulls/testlibrary.jpg';
import dkny from './../images/fulls/dkny.png';
import dknyPdf from './../images/fulls/dkny.pdf';

const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};



const UxUi = props => {

  const [state, setState] = useState({
    fweets: [],
    loaded: false,
    error: false
  })
  const history = useHistory();

  // Fetch the fweets on first load.
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    }
  }, [user])


return (
  <>
  <header className="hero">
  <h2>Product UX/UI</h2>
    <p>Lots more available...</p>
</header>
<main className="main-content project-wrapper">
<SRLWrapper options={options}>

{/* <section style={{flexDirection:'column', padding:'2rem'}}>
</section>
<section>
<img src={planning} />
</section> */}





{/* <section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>MyDataHelps Designer Revamp</h3>  
    <p>Care Evolution | 2022</p>
  </div>
  <div className="image-wrap" style={{textAlign:'center',border:'1px solid #ccc'}}>
    Coming soon
    <a href={anc}>
        <img src={anc} alt="Designing the centralized application's content and information architecture" />
    </a>
  </div>
</section> */}

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Rosetta Dev Portal</h3>  
    <p>Care Evolution | 2022</p>
    <p>This was mocked up in one day as a Hackathon project, and can now be seen in production: <a href="https://rosetta.careevolution.com/dashboard" target="_blank">here</a></p>
    <p>Case study coming soon</p>
  </div>

  <div className="image-wrap thumbnails">
          <div className="lg-react-element">
            <a href={rosetta01}>
              <img src={rosetta01} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>
            <a href={rosetta02}>
              <img src={rosetta02} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>
            <a href={rosetta03}>
              <img src={rosetta03} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>
            <a href={rosetta04}>
              <img src={rosetta04} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>
            <a href={rosetta05}>
              <img src={rosetta05} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>
            <a href={rosetta06}>
              <img src={rosetta06} alt="Rosetta Dev Portal: This was mocked up in one day as a Hackathon project. Case study coming soon." />
            </a>

          </div>

        {/* </SRLWrapper> */}

  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>In-Product Sample App Tutorial</h3>  
    <p>FaunaDB | 2021</p>
    <p>I designed this onboarding tutorial to get users up-and-running with a sample app ASAP.
        Once users have a live site, they tend to get that "just a little more coding" feeling, 
        leading to heavier driver usage and overall retention.</p>
  </div>
  <div className="image-wrap">
    <a href={sampleapp}>
        <img className="border" src={sampleapp} alt='I designed this onboarding tutorial to get users up-and-running with a sample app ASAP.
        Once users have a live site, they tend to get that "just a little more coding" feeling, 
        leading to heavier driver usage and overall retention.' />
    </a>
  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Checkout Funnel Redesign</h3>  
    <p>GoSection8 | 2017</p>
    <p>This redesign significantly increased revenue. I designed and coded (.NET, CSS, jQuery) the more app-like mobile checkout experience, 
								and optimized the existing desktop experience with some minor tweaks and a new "Compare" page.
    </p>
  </div>
  <div className="image-wrap">
    <a href={checkout}>
        <img src={checkout} alt='This checkout funnel redesign significantly increased revenue. I designed and coded (.NET, CSS, jQuery) the more app-like mobile checkout experience, 
								and optimized the existing desktop experience with some minor tweaks and a new "Compare" page.' />
    </a>
  </div>
</section>


<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>A/B and MVO test results library</h3>  
    <p>Bankrate.com | 2016-2017</p>
    <p>Strategized, designed, and developed an executive-level custom reporting dashboard for analyzing results of revenue-weighted A/B and multivariate tests.

    </p>
  </div>
  <div className="image-wrap">
    <a href={bankrate}>
        <img src={bankrate} alt="Strategized, designed, and developed an executive-level custom reporting dashboard for analyzing results of revenue-weighted A/B and multivariate tests." />
    </a>
  </div>
</section>

<section>
  <div className="text-wrap">
    <h3 style={{marginBottom:'0'}}>Adaptive multivariate optimization (AMVO)</h3>  
    <p>HiConversion | 2014</p>
    <p><a href={dknyPdf} target="_blank">AMVO design doc for DKNY (pdf)</a></p>
    <p>Designed and analyzed AMVO campaigns for multiple companies, including DKNY, Pier 1, National Geographic, Lenscrafters, Loreal, Lancome, Burt's Bees, TRX, and Fathead.</p>
  </div>
  <div className="image-wrap">
    <a href={dkny}>
        <img className="border" src={dkny} alt="(Close this gallery for link to sample design doc.) Designed and analyzed AMVO campaigns for multiple companies, including DKNY, Pier 1, National Geographic, Lenscrafters, Loreal, Lancome, Burt's Bees, TRX, and Fathead." />
    </a>
  </div>
</section>



</SRLWrapper>

<section className="center prev-next">
  <h3>
    <Link to="/commerce-signals">
      <FontAwesomeIcon icon={faArrowLeft} />Ad Campaign Dashboard
    </Link>
    <span>|</span>
    <Link to="/web-design-dev">
    Web Design & Development<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>
</main>
</>

  )
}
UxUi.propTypes = {}

export default UxUi