import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const ContactForm = props => {
  // const [username, setUsername] = useState('')
  // const [password, setPassword] = useState('')
  // const [name, setName] = useState('')
  // const [alias, setAlias] = useState('')

  // const handleChangeUserName = event => {
  //   setUsername(event.target.value)
  // }

  // const handleChangePassword = event => {
  //   setPassword(event.target.value)
  // }

  // const handleChangeName = event => {
  //   setName(event.target.value)
  // }

  // const handleChangeAlias = event => {
  //   setAlias(event.target.value)
  // }

  const linkInfo = props.isLogin
    ? { linkText: 'request access', link: 'register' }
    : { linkText: 'Already have an account? Login here!', link: 'login' }

  return (
    <React.Fragment>
      <div className="account-form-container">
        <form  className="account-form"
              action="https://formspree.io/f/mpzklbvn"
              method="POST"
            >
            <div className="input-row">
            <label for="email" className="input-row-column">
                Your email  </label>
                <input id="email" type="email" name="_replyto" />
            
            </div>

              <div className="input-row">
              <label for="message" className="input-row-column">
                Your message
              </label>
                <textarea rows="4" id="message" name="message"></textarea>

</div>


              <button type="submit">Send</button>
        </form>
      </div>
    </React.Fragment>
  )
}

ContactForm.propTypes = {
}

export default ContactForm
