import React, { useState, useEffect, useContext } from 'react'
import { faunaQueries } from '../fauna/query-manager'

import { toast } from 'react-toastify'
// import { safeVerifyError } from '../fauna/helpers/errors'
// import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionContext from '../context/session'
import { useHistory } from 'react-router-dom'
import { safeVerifyError } from '../fauna/helpers/errors'
import { SRLWrapper } from "simple-react-lightbox";

import csNew from './../images/fulls/cs-campaigns-new.png';
import csOld from './../images/fulls/cs-campaigns-old.png';
import csGlossary from './../images/fulls/cs-glossary.png';
import csFlow from './../images/fulls/cs-new-user-flow.png';
import csSignal from './../images/fulls/cs-signal-edit.png';
import csTraining from './../images/fulls/cs-training.png';
import csFramed from './../images/fulls/cs-campaigns-framed.png';
const options = {
  buttons: {
    showDownloadButton: false,
    // showFullscreenButton: false,
    // showThumbnailsButton: false,
    backgroundColor: "transparent"
  }
};


const CS = ({ match, location, props }) => {
  // // const [fweets] = useState("placeholder")
  // const [state, setState] = useState({
  //   fweets: [],
  //   loaded: false,
  //   error: false
  // })

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const history = useHistory();
  // const profileToFetch = match.params.userAlias
  // // const profileToFetch = "hi"
  // console.log("profileToFetch", profileToFetch)
  const [profileData, setProfileData] = useState(undefined)
  // async function getUserProfile () {
  //   return faunaQueries
  //     // .getUserProfile(profileToFetch)
  //     .getProjects()
  //     .then(res => {
  //       let fetchedProfile = res.data
  //       console.log(fetchedProfile)
  //       return fetchedProfile
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       // toast.error('get project failed')
  //     })
  // }

  useEffect(() => {
    if (!user) {
      history.push('accounts/login')
    } else{
      // let didCancel = false
      // async function fetchData () {
      //   if (didCancel) return
      //   let fetchedProfile = await getUserProfile()
      //    setProfileData({
      //     // memeList: memeList,
      //     profileDetails: fetchedProfile
      //   })
      //   //profileData is still undefined the first time this useEffect runs
      // }
      // fetchData()
      // return function () {didCancel = true}
    }
  }, [])

  // return renderProfile()
  // function renderProfile () {
    // if (profileData === undefined) return (<React.Fragment><h1>Loading ... </h1></React.Fragment>)
    // let thisProject = profileData.profileDetails
    // console.log("thisProject", thisProject)

    const options = {
      showNextButton: false,
      showPrevButton: false,
      buttons: {
        backgroundColor: 'transparent',
      }
    }
  

  return (
    <>
    <header className="hero">
    <h1>Commerce Signals Dashboard</h1>
<p>November, 2017 - March, 2018</p>
<p>Commerce Signals solved the hard problem of correlating consumer spend both 
  in-store and online with multi-channel ad impressions (digital, TV, etc.) by creating a patented backend platform through which merchants and agencies
          could access previously off-limits consumer financial data in a privacy-centric way.</p>
  </header>
    <main className="main-content project-wrapper">
    <SRLWrapper options={options}>
      
{/* 
      <section>
      <LightGallery></LightGallery>
      </section> */}


<section><div className="text-wrap">
        <h3>Project summary</h3>    
<p>The problem left to solve was how to communicate this unique value proposition through the platform’s GUI in an intuitive, self-serve way. 
  This required not only redesigning the GUI, but redefining the product’s object hierarchy and experimentation framework.
</p>
</div><div className="image-wrap">
        <img src={csFramed} alt="This project required not only redesigning the GUI, but redefining the product’s object hierarchy and experimentation framework"/>
        </div>
</section>


<section><div className="text-wrap">
        <h3><strong>Step 1 </strong>| Understand the problems </h3>    
<p>Customers’ first complaint was the lack of a top-level results view. It took an excruciatingly long time to manually drill in and out of every campaign to see results. Second, there was no way to group related “campaigns”, making it even more time-consuming. 
    </p> 
    <p>Although part of Commerce Signals’ core value proposition was near real-time results that customers could act on more quickly than other data providers, it was impossible for users to access those results efficiently. This was such a problem that most users didn’t even consult the dashboard, instead relying on weekly “Campaign summary” emails from Commerce Signals' Data Analysts.
    </p>
    </div><div className="image-wrap">
        <img src={csOld} alt="Customers’ first complaint was the lack of a top-level results view. It took an excruciatingly long time to manually drill in and out of every campaign to see results. Second, there was no way to group related “campaigns”, making it even more time-consuming.

Although part of Commerce Signals’ core value proposition was near real-time results that customers could act on more quickly than other data providers, it was impossible for users to access those results efficiently. This was such a problem that most users didn’t even consult the dashboard, instead relying on weekly “Campaign summary” emails from Commerce Signals' Data Analysts."/>
        </div>
</section>

<section><div className="text-wrap">
        <h3><strong>Step 2 </strong>| Terminology and object hierarchy </h3>    
{/* <p>One of the first steps in designing dashboards for technical products, even before personas or user flows, is collaborating 
  with Engineering to understand the underlying product, and writing a glossary and object hierarchy to circulate with other stakeholders. 
    </p> */}
    <p>Engineering, Sales, Product, and Marketing all had slightly different definitions of core product features.
        For example, what the frontend called “campaigns” were actually “studies”. And there was no way to group related “studies” into actual “campaigns” because a true 
        “campaign” object did not exist yet in the backend, even though it looked like it did. 
    </p>
    <p>So I collaborated with Engineering to write a glossary and object hierarchy to circulate with stakeholders in Product and Marketing. </p>
{/* <p>This glossary also included a light version of our different user personas--focusing on users’ job titles, organizational affiliations, and jobs to be done. Unless requested, I don’t tend to build fancy user personas with photos, hobbies, pets’ names, etc.</p>   */}
       </div><div className="image-wrap">
        <img src={csGlossary} alt="Engineering, Sales, Product, and Marketing all had slightly different definitions of core product features. For example, what the frontend called “campaigns” were actually “studies”. And there was no way to group related “studies” into actual “campaigns” because a true “campaign” object did not exist yet in the backend, even though it looked like it did.

So I collaborated with Engineering to write a glossary and object hierarchy to circulate with stakeholders in Product and Marketing."/>
        </div>
</section>

<section><div className="text-wrap">
        <h3><strong>Step 3 </strong>| New information architecture </h3>    
<p>
After we all agreed on what to call things, we started mapping out user flows and jobs to be done for our different personas. This involved collaborating with the Data Analysts on a more robust yet user-friendly experimentation framework. (My background in stats and research methods came in handy here!) 
</p>
   <p>This allowed the engineering team to start backend work without being held up by my designs. For example, we needed to create a top-level “campaign” layer on the backend in order to expose those top-level results summaries. From the top-level campaign object, users would then drill into individual studies, then drill down even further for comparative analysis of different variables, prescriptive guidance on how to improve their control groups, etc.
     </p>         </div><div className="image-wrap">
        <img src={csFlow} alt='We needed to create a top-level “campaign” layer on the backend in order to expose top-level results summaries. From the top-level campaign object, users would then drill into individual studies, then drill down even further for comparative analysis of different variables, prescriptive guidance on how to improve their control groups, etc.'/>
        </div>
</section>

<section><div className="text-wrap">
        <h3><strong>Step 4 </strong>| Design </h3>    
<p>Introducing this new layer for true “campaigns” into the product’s object hierarchy paved the way for a simultaneously less confusing and more information-rich dashboard experience. The side nav with several confusing and infrequently used items was converted into a top nav with only the most commonly accessed items. This also created more horizontal space for additional columns in the tables--something we needed in other views. We also converted the table rows into easily expandable accordions for a quick top-level overview of all campaign results.

</p>
         </div><div className="image-wrap">
        <img src={csNew} alt="Introducing this new layer for true “campaigns” into the product’s object hierarchy paved the way for a simultaneously less confusing and more information-rich dashboard experience. The side nav with several confusing and infrequently used items was converted into a top nav with only the most commonly accessed items. This also created more horizontal space for additional columns in the tables--something we needed in other views. We also converted the table rows into easily expandable accordions for a quick top-level overview of all campaign results.
"/>
        </div>
</section>

<section><div className="text-wrap">
        <h3><strong>Step 5 </strong>| Build and release </h3>    
<p>As we approached the deadline, I jumped in to help the frontend team polish up the UI to look more like the mocks. </p>
 <p> I also helped create documentation and slide decks to train existing users on the new object hierarchy and UI. Users expressed that the new information architecture solved most of their pain points. 
</p>
<p>
I left Commerce Signals shortly after the new dashboard was released to production. They seem to be doing well, and have since been acquired by the Financial division of Verisk Analytics. 
</p>
         </div><div className="image-wrap">
        <img src={csTraining} alt="As we approached the deadline, I jumped in to help the frontend team polish up the UI to look more like the mocks.
I also helped create documentation and slide decks to train existing users on the new object hierarchy and UI. Users expressed that the new information architecture solved most of their pain points. 
I left Commerce Signals shortly after the new dashboard was released to production. They seem to be doing well, and have since been acquired by the Financial division of Verisk Analytics. "/>
        </div>
</section>
</SRLWrapper>

<section className="center prev-next">
  <h3>
    <Link to="/gs8-waitlist">
      <FontAwesomeIcon icon={faArrowLeft} />Centralized Section 8 Waitlist
    </Link>
    <span>|</span>
    <Link to="/ux-ui">
     Assorted Product UX/UI<FontAwesomeIcon icon={faArrowRight} />
    </Link>
  </h3>
</section>
</main>
</>

  )
}
// }
CS.propTypes = {}

export default CS