import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import Particles from "react-tsparticles";

import Nav from './../components/nav'
import Footer from '../components/footer'
import Notice from '../components/notice'

const Layout = props => {
  return (
    <div className="page">
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap"
        rel="stylesheet"
      ></link>
      {/* //TODO: move this to only the home page */}
       <Particles id="particles-js" style={{ position: 'fixed' }}
        params={
          {
            particles: {
              number: {
                value: 250,
                density: { enable: true, value_area: 1362.9002517356944 }
              },
              color: { value: "#6974c8" },
              shape: {
                type: "circle",
                stroke: { width: 0, color:  "#6974c8" },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
              },
              size: {
                value: 4,
                random: false,
                anim: { enable: true, speed: 2.4362316369040355, size_min: 0, sync: true }
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#6974c8",
                opacity: 0.48102361825965684,
                width: 1
              },
              move: {
                enable: true,
                speed: .25,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 }
              }
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: { enable: true, mode: "bubble" },
                onclick: { enable: true, mode: "push" },
                resize: true
              },
              modes: {
                grab: {
                  distance: 194.89853095232286,
                  line_linked: { opacity: 0.7 }
                },
                bubble: { distance: 100, size: 20, duration: 4, opacity: .2, speed: 1 },
                repulse: { distance: 150, duration: 0.4 },
                // push: { particles_nb: 4 },
                remove: { particles_nb: 2 }
              }
            },
            retina_detect: true
          }
        }
    />
      <div className="body-container">
        <Nav/>
        <Notice></Notice>
        {props.children}

        <Footer/>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
