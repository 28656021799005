import React, { useState } from 'react'
import { faunaQueries } from '../fauna/query-manager'
import { toast } from 'react-toastify'
import { safeVerifyError } from '../fauna/helpers/errors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'


import resume from './../images/Summer_Schrader_Product_Design_Lead.pdf';

import Th10 from './../images/fulls/fauna-dashboard-framed.png';
import abac from './../images/fauna-abac/fauna-abac-framed.png';
import forums from './../images/fulls/fauna-forums.png';
import docsQs from './../images/fulls/fauna-docs-framed.png';
// import userResearch from './../images/fulls/fauna-user-research.png';

import csFlow from './../images/fulls/cs-campaigns-framed.png';
import Th6 from './../images/fulls/gs8-dashboard.png';
import kansas from './../images/fulls/kansas-th.png';
import art from './../images/fulls/fa-th.jpg';
import rosetta04 from './../images/fulls/ce-rosetta-inbound.png';
// import summerFace from './../images/summer-face.png';

const Intro = props => {
  return (
    <>
<header className="hero">
  <h1>summer schrader</h1>
  <h3>UX &#8226; PM &#8226; FE</h3>
  <p style={{zIndex:'9999'}}><a target="_blank" href={resume}>Resume (pdf)</a></p>


</header>

<main className="main-content">
  <div className="gridywrap">
    <section className="center">
              <h2>Database case studies</h2>
         <div className="thumbnail-row row-of-4">
          
                  <div className="gridy-item">
                    <img src={Th10}></img>
                    
                   <Link to="/fauna">
                    <div className="gridinfo">

                    
                      <h3>Database Management System</h3>
                      <div className="gridmeta">
                        <p className="gridwhen">
                          FaunaDB | 5/18 - 8/21</p>
                      </div>
                      <span className="grid-btn grid-more">
                        <span>More</span> 
                      </span>
                    </div>
                    </Link>  

                  <Link to="/fauna"><div className="label">Database Management System</div> </Link>  

                  </div>
                  <div className="gridy-item">
                    <img src={forums}></img>

                    <Link to="/fauna-dx">
                    <div className="gridinfo">


                      <h3>Developer Experience Program</h3>
                      <div className="gridmeta">
                        <p className="gridwhen">
                          FaunaDB | 5/18 - 8/21</p>
                      </div>
                      <span className="grid-btn grid-more">
                        <span>More</span> 
                      </span>
                    </div>
                    </Link>  

                    <Link to="/fauna-dx"><div className="label">Developer Experience Program</div> </Link>  

                    </div>


                  <div className="gridy-item">
                    <img src={abac}></img>
                   <Link to="/fauna-abac">
                    <div className="gridinfo">
                      <h3>Database Access Control</h3>
                      <div className="gridmeta">
                        <p className="gridwhen">
                          FaunaDB | 7/19, 7/21</p>
                      </div>
                                     <span className="grid-btn grid-more">
                        <span>More</span> 
                    
                      </span>
                    </div>
                    </Link>  
                    <Link to="/fauna-abac"><div className="label">Database Access Control</div> </Link>  
                  </div>


         <div className="gridy-item">
                    <img src={docsQs}></img>
                   <Link to="/fauna-docs">
                    <div className="gridinfo">
                      <h3>Database Docs</h3>
                      <div className="gridmeta">
                        <p className="gridwhen">
                          FaunaDB | 4/18 - 4/21</p>
                      </div>
                      <span className="grid-btn grid-more">
                        <span>More</span> <i className="fa fa-plus"></i>
                      </span>
                    </div>
                    </Link>
                    <Link to="/fauna-docs"><div className="label">Database Docs</div> </Link>    
                  </div>
            </div>
    </section>

    <section className="center">
    <h2>Other case studies</h2>
    <div className="thumbnail-row row-of-4">
    <div className="gridy-item">
                  <img src={Th6}></img>
                  <Link to="/gs8-waitlist">
                  <div className="gridinfo">
                    <h3>Centralized Section 8 Waitlist</h3>
                    <div className="gridmeta">
                      <p className="gridwhen">GoSection8 | 3/16 - 6/17</p>
                    </div>
                    <span className="grid-btn grid-more">
                        <span>More</span> <i className="fa fa-plus"></i>
                      </span>
                  </div>
                </Link>
                <Link to="/gs8-waitlist"><div className="label">Centralized Section 8 Waitlist</div> </Link>  
                </div>


                  <div className="gridy-item">
                  <img src={csFlow}></img>
                  <Link to="/commerce-signals">
                  <div className="gridinfo">
                    <h3>Ad Campaign Dashboard</h3>
                    <div className="gridmeta">
                      <p className="gridwhen">Commerce Signals | 11/17 - 3/18</p>
                    </div>
                    <span className="grid-btn grid-more">
                        <span>More</span> <i className="fa fa-plus"></i>
                      </span>
                  </div>
                </Link>
                <Link to="/commerce-signals"><div className="label">Ad Campaign Dashboard</div> </Link>  
                </div>
</div>
    </section>

    <section className="center">
              <h2>Other work</h2>


         <div className="thumbnail-row row-of-4">

         <div className="gridy-item">
              <img src={rosetta04}></img>
              <Link to="/ux-ui">
              <div className="gridinfo">
                <h3>Assorted Product UX/UI</h3>
                 <span className="grid-btn grid-more">
                    <span>More</span> <i className="fa fa-plus"></i>
                  </span>
              </div>
            </Link>
            <Link to="/commerce-signals"><div className="label">Assorted Product UX/UI</div> </Link>  
            </div>




            <div className="gridy-item">
              <img src={kansas}></img>
              <Link to="/web-design-dev">
              <div className="gridinfo">
                <h3>Web Design & Development</h3>
                <span className="grid-btn grid-more">
                    <span>More</span> <i className="fa fa-plus"></i>
                  </span> 
              </div>
            </Link>
            <Link to="/web-design-dev"><div className="label">Web Design & Development</div> </Link>  
            </div>

            <div className="gridy-item">
              <img src={art}></img>
              <Link to="/graphic-design">
              <div className="gridinfo">
                <h3>Art & Graphic Design</h3>
                <span className="grid-btn grid-more">
                    <span>More</span> <i className="fa fa-plus"></i>
                  </span>
              </div>
            </Link>
            <Link to="/graphic-design"><div className="label">Art & Graphic Design</div> </Link>  
            </div>




            </div>
   </section>
            

</div>
</main>
</>
  )
}
Intro.propTypes = {}

export default Intro