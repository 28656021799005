import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SessionContext from '../context/session'

const Form = props => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [alias, setAlias] = useState('')

  // const [state, setState] = useState({
  //   fweets: [],
  //   loaded: false,
  //   error: false
  // })
  // const history = useHistory();

  // // Fetch the fweets on first load.
  // const sessionContext = useContext(SessionContext)
  // const { user } = sessionContext.state

  // useEffect(() => {
  //   if (!user) {
  //     history.push('/login')
  //   }
  // }, [user])


  const handleChangeUserName = event => {
    setUsername(event.target.value)
  }

  const handleChangePassword = event => {
    setPassword(event.target.value)
  }

  const handleChangeName = event => {
    setName(event.target.value)
  }

  const handleChangeAlias = event => {
    setAlias(event.target.value)
  }

  const linkInfo = props.isLogin
    ? { linkText: 'request access', link: '../contact' }
    : { linkText: 'Already have an account? Login here!', link: 'contact' }

  return (
    <React.Fragment>
      <div className="account-form-container">
        <form className="account-form" onSubmit={e => props.handleSubmit(e, username, password, alias, name)}>
          {/* {props.isLogin ? null : renderInputField('name', name, 'text', e => handleChangeName(e))}
          {props.isLogin ? null : renderInputField('company', alias, 'text', e => handleChangeAlias(e))} */}
          {renderInputField('username', username, 'text', e => handleChangeUserName(e), 'username')}
          {renderInputField('password', password, 'password', e => handleChangePassword(e), 'current-password')}
          <div className="input-row align-right">
            <button className={props.isLogin ? 'login' : 'register'}> {props.isLogin ? 'log in' : 'send request'} </button>
          </div>
          <Link to={linkInfo.link}> {linkInfo.linkText}</Link>
        </form>
      </div>
    </React.Fragment>
  )
}

const renderInputField = (name, value, type, fun, autocomplete) => {
  const lowerCaseName = name.toLowerCase()
  return (
    <div className="input-row">
      <label htmlFor="{lowerCaseName}" className="input-row-column">
        {name}
      </label>
      <input
        className="input-row-column"
        value={value}
        onChange={fun}
        type={type}
        id={lowerCaseName}
        name={lowerCaseName}
        autoComplete={autocomplete}
      />
    </div>
  )
}

Form.propTypes = {
  isLogin: PropTypes.bool,
  handleSubmit: PropTypes.func
}

export default Form
