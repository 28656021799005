import React, { useState, useContext } from 'react'
import { faunaQueries } from '../fauna/query-manager'
import { toast } from 'react-toastify'
import { safeVerifyError } from '../fauna/helpers/errors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SessionContext from '../context/session'
import { Link } from 'react-router-dom'

const Footer = props => {
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  if (!user){
    return (
      <footer className="center" style={{padding:"2.5%"}}>
        <h3>
        <Link to='/contact'>say hello!</Link>
        </h3>
      </footer>
    )
  } else {
    return (
      <footer className="center">
 
<h3><Link to='/contact'>say hello!</Link></h3> 
        <div><a href="mailto:summer.schrader@gmail.com" target="_blank">summer.schrader@gmail.com</a>
          </div>
        <div><a href="tel:929-531-0135">text/call: 929-531-0135</a></div>
      </footer>
    )
  }
}
Footer.propTypes = {}

export default Footer